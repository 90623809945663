import { ADD_VOLUNTEER, VOLUNTEER_ERROR, CONTACT_US } from "../constants/Types";

const initialState = {
  loading: true,
  volunteer_message: "",
  contact_message: "",
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_VOLUNTEER:
      return {
        ...state,
        volunteer_message:
          "Volunteer Request Send Succesfully. We will get back to you soon",
        loading: false,
      };
    case CONTACT_US:
      return {
        ...state,
        contact_message:
          "Thnank you for contacting with us. We will get back to you soon",
        loading: false,
      };
    case VOLUNTEER_ERROR:
      return {
        ...state,
        error: "Something Went Wrong",
        loading: false,
      };

    default:
      return state;
  }
}
