import { GET_TEAMS, TEAM_ERROR } from "../constants/Types";

const initialState = {
  teams: [],
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TEAMS:
      return {
        ...state,
        teams: payload,
        loading: false,
      };
    case TEAM_ERROR:
      return {
        ...state,
        error: "Something Went Wrong",
        loading: false,
      };

    default:
      return state;
  }
}
