import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Breadcumb(props) {
  return (
    <Fragment>
      <div
        className="inner-banner has-base-color-overlay text-center"
        style={{ background: "url(/images/background/4.jpg)" }}
      >
        <div className="container">
          <div className="box">
            <h1>{props.heading}</h1>
          </div>
        </div>
      </div>
      <div className="breadcumb-wrapper">
        <div className="container">
          <div className="pull-left">
            <ul className="list-inline link-list">
              <li>
                <Link to="/">Home</Link>
              </li>
              {props.subheading && (
                <li>
                  <a> {props.subheading}</a>
                </li>
              )}
              {props.heading && <li>{props.heading}</li>}
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Breadcumb;
