import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Wrapper(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="boxed_wrapper">
      {/* <div className="top-bar">
        <div className="container">
          <div className="clearfix">
            <div className="top-bar-text float_left">
              <a
                href="https://rzp.io/l/homengo"
                target="_blank"
                className="thm-btn donate-box-btn"
              >
                donate
              </a>
            </div>
            <div className="right-column float_right">
              <ul className="list_inline contact-info">
                <li>
                  <span className="icon-phone" />
                  Phone: 1800 103 4683
                </li>
                <li>
                  <span className="icon-back" />
                  Email: support@nationalngo.org
                </li>
                <li className="social-icon">
                  <a
                    href="https://www.facebook.com/NationalNGOIndia"
                    target="_blank"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UC8n8zgj6kFz9DZfuyRxS1LQ"
                    target="_blank"
                  >
                    <i className="fa fa-youtube"></i>
                  </a>
                  <a href="https://twitter.com/TheNationalNGO" target="_blank">
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a
                    href="https://plus.google.com/+NationalNGOreviews"
                    target="_blank"
                  >
                    <i className="fa fa-google-plus"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/national-ngo"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      {props.children}
      <section className="call-out">
        <div className="container">
          <div className="float_left">
            <h4>
              Start with helping one poor person and pave way for the bigger
              changes!
            </h4>
          </div>
          <div className="float_right">
            <Link className="thm-btn style-3" to="/become-a-volunteer">
              Become a Volunteer
            </Link>
          </div>
        </div>
      </section>
      <footer className="main-footer">
        {/*Widgets Section*/}
        <div className="widgets-section">
          <div className="container">
            <div className="row">
              {/*Big Column*/}
              <article className="big-column col-md-6 col-sm-12 col-xs-12">
                <div className="row clearfix">
                  {/*Footer Column*/}
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="footer-widget about-column">
                      <div className="section-title">
                        <h4>About Us</h4>
                      </div>
                      <div className="text">
                        <p>
                          Nearly 1.73 million children die in India every year
                          due to lack of treatment. We work with communities to
                          ensure that everyone receives adequate nutrition and
                          healthcare services.
                        </p>
                        <p>
                          <i
                            className="fa fa-map-marker"
                            style={{ color: "#fed857" }}
                          ></i>{" "}
                          TA – 204, 1st Floor, Ravidas Marg, Tughlakabad
                          Extension, New Delhi-110019
                        </p>
                        <p>
                          <i
                            className="fa fa-phone"
                            style={{ color: "#fed857" }}
                          ></i>{" "}
                          1800 103 4683
                        </p>
                        <p>
                          <i
                            className="fa fa-envelope"
                            style={{ color: "#fed857" }}
                          ></i>{" "}
                          support@nationalngo.org
                        </p>
                      </div>
                    </div>
                  </div>
                  {/*Footer Column*/}
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="footer-widget link-column">
                      <div className="section-title">
                        <h4>Quick Links</h4>
                      </div>
                      <div className="widget-content">
                        <ul className="list">
                          <li>
                            <Link to="/who-we-are">About Us</Link>
                          </li>
                          <li>
                            <Link to="/national-calamities">
                              National Calamities
                            </Link>
                          </li>
                          <li>
                            <Link to="/our-team">Our Team</Link>
                          </li>
                          <li>
                            <Link to="/rehabs">Rehab</Link>
                          </li>
                          <li>
                            <Link to="/events">Events</Link>
                          </li>
                          <li>
                            <Link to="/press-release"> Press Release</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              {/*Big Column*/}
              <article className="big-column col-md-6 col-sm-12 col-xs-12">
                <div className="row clearfix">
                  {/*Footer Column*/}
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="footer-widget link-column">
                      <div className="section-title">
                        <h4>&nbsp; </h4>
                      </div>
                      <div className="widget-content">
                        <ul className="list">
                          <li>
                            <Link to="/current-beneficiaries">
                              Current Beneficiaries
                            </Link>
                          </li>
                          <li>
                            <Link to="/success-stories">
                              Successful Stories
                            </Link>
                          </li>
                          <li>
                            <Link to="/champions">Champions</Link>
                          </li>
                          <li>
                            <Link to="/blog"> Blog </Link>
                          </li>
                          <li>
                            <Link to="/testimonials"> Testimonials </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/*Footer Column*/}
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="footer-widget link-column">
                      <div className="section-title">
                        <h4>Terms & Policies</h4>
                      </div>
                      <div className="widget-content">
                        <ul className="list">
                          <li>
                            <Link to="/donate">Donation</Link>
                          </li>

                          <li>
                            <Link to="/terms-and-conditions">
                              Terms and Conditions
                            </Link>
                          </li>
                          <li>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                          </li>
                          <li>
                            <Link to="/cancellation-policy">
                              Cancellation & Refund Policy
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact-us">Contact Us</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </footer>
      {/*Footer Bottom*/}
      <section className="footer-bottom">
        <div className="container">
          <div className="pull-left copy-text">
            <p>
              Copyrights © 2020{" "}
              <a href="#"> National Organisation for Social Empowerment</a> All
              Rights Reserved.
            </p>
          </div>
          {/* /.pull-right */}

          {/* /.pull-left */}
        </div>
        {/* /.container */}
      </section>
      {/* Scroll Top  */}
      <button className="scroll-top tran3s color2_bg">
        <span className="fa fa-angle-up" />
      </button>{" "}
    </div>
  );
}

export default Wrapper;
