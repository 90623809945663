import React, { useState } from 'react';

function SingleCauseSidebar({ item, hideGoal }) {
  const [amount, setamount] = useState('');
  const [amountError, setAmountError] = useState(false);
  const [currency, setcurrency] = useState('Rs');
  const proceedDonate = () => {
    if (amount === '') {
      setAmountError(true);
    } else {
      console.log('Clicked', item);
      window.history.pushState(
        'item',
        'Item',
        `/donate?amount=${amount}&&currency=${currency}&benificiary=${item.name}`
      );
      // history.push({ pathname: "/donate", state: { item, amount, currency } });
    }
  };
  const goal = item.goal;
  const totalDonation = item.raised_amount;

  const angleOfRotation = (totalDonation / goal) * 360;
  const percentageDonation = parseInt((totalDonation / goal) * 100);
  console.log(percentageDonation);

  return (
    <div>
      <div>
        <div>
          {!hideGoal && (
            <div
              className='content'
              style={{
                display: 'flex',
                backgroundColor: '#fff',
                justifyContent: 'space-between',
              }}
            >
              <div
                className='radial-progress'
                style={{
                  backgroundColor: angleOfRotation > 180 ? '#fed857' : '#333',
                }}
              >
                <div>
                  <div className='circle'>
                    <div
                      className='mask full'
                      style={{ transform: `rotate(${angleOfRotation}deg)` }}
                    >
                      <div
                        className='fill'
                        style={{
                          transform: `rotate(${angleOfRotation}deg)`,
                          backgroundColor:
                            angleOfRotation > 180 ? '#333' : '#fed857',
                        }}
                      />
                    </div>
                    <div className='mask half'>
                      <div className='fill' />
                      <div className='fill fix' />
                    </div>
                    <div className='shadow' />
                  </div>
                  <div className='inset'>
                    <div className='percentage'>
                      <div className='numbers'>
                        <span> {percentageDonation}% </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='donate' style={{ padding: '20px 20px' }}>
                <div className='loader-value'>
                  <ul>
                    <li>
                      <span>Current</span>₹{item.raised_amount}
                    </li>
                    <li>
                      <span>Target</span>₹{item.goal}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}

          <div style={{ backgroundColor: '#fff' }}>
            <div className='selector' style={{ padding: '0px 20px' }}>
              <h3
                style={{
                  position: 'relative',
                  paddingBottom: '10px',
                  marginBottom: '10px',
                }}
              >
                Donate
              </h3>

              <div style={{ display: 'flex' }}>
                <input
                  type='number'
                  id='fname'
                  name='fname'
                  min={1}
                  value={amount}
                  style={{ width: '90%' }}
                  onChange={(event) => {
                    setamount(event.target.value);
                    setAmountError(false);
                  }}
                />
              </div>
              {amountError && (
                <p style={{ color: 'red' }}> *Please add Amount to Proceed </p>
              )}
              <div className='evnt'>
                <a href='#donate' onClick={() => proceedDonate()}>
                  donate now
                </a>
              </div>
            </div>
            {
              //   <div className='bar-cont'>
              //   <p>Payment options: Online, cheque pickups</p>
              //   <span>or</span>
              //   <img
              //     src={
              //       item.slug === 'tanu'
              //         ? '/assets/images/tanu-scan-code.jpeg'
              //         : '/images/scan-code.jpg'
              //     }
              //   />
              //   <p className='scaner'>Scan &amp; donate with any app</p>
              // </div>
              // <div className='payment'>
              //   <ul>
              //     <li>
              //       <img src='/images/bhim.png' alt='Bhim' />
              //     </li>
              //     <li>
              //       <img src='/images/g-pay.png' alt='Google Pay' />
              //     </li>
              //     <li>
              //       <img src='/images/paytm.png' alt='Paytm' />
              //     </li>
              //     <li>
              //       <img src='/images/pay.png' alt='Pay' />
              //     </li>
              //   </ul>
              //   <div
              //     className='payment'
              //     style={{ textAlign: 'center', padding: '20px' }}
              //   ></div>
              // </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleCauseSidebar;
