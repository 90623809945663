import React, { useState } from "react";
import { Redirect } from "react-router-dom";

function DonateNow({ item }) {
  const [amount, setamount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [currency, setcurrency] = useState("Rs");
  const proceedDonate = () => {
    if (amount === "") {
      setAmountError(true);
    } else {
      console.log("Clicked", item);
      window.history.pushState(
        "item",
        "Item",
        `/donate?amount=${amount}&&currency=${currency}&benificiary=${item.name}`
      );
      // history.push({ pathname: "/donate", state: { item, amount, currency } });
    }
  };
  console.log("Beneficiary", item);
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div className="selector" style={{ padding: "20px 20px" }}>
        <h3
          style={{
            position: "relative",
            paddingBottom: "10px",
            marginBottom: "10px",
          }}
        >
          Donate
        </h3>

        <div
          style={{
            display: "flex",
            flexDirection: window.innerWidth < 700 ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: 4 }}>
            <input
              type="number"
              id="fname"
              name="fname"
              value={amount}
              min={1}
              style={{
                width: window.innerWidth < 700 ? "100%" : "90%",
                marginLeft: window.innerWidth < 700 ? "0px" : "10px",
              }}
              onChange={(event) => {
                setamount(event.target.value);
                setAmountError(false);
              }}
            />
            {amountError && (
              <p style={{ color: "red" }}>
                {" "}
                *Pease add amount to proceed{" "}
              </p>
            )}
          </div>
          <div className="evnt" style={{ flex: 2 }}>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => proceedDonate()}

              href="#donate"
            >
              donate now
            </a>
          </div>
        </div>
      </div>
      {
        //   <div className="bar-cont" style={{ padding: "20px" }}>
        //   <p>Payment options: Online, cheque pickups</p>
        //   <span>or</span>
        //   <img
        //     alt="Scanner"
        //     src={"/images/scan-code.jpg"}
        //     style={{ width: "20%" }}
        //   />
        //   <p className="scaner">Scan &amp; donate with any app</p>
        // </div>
        // <div className="payment" style={{ padding: "0px 20%" }}>
        //   <ul>
        //     <li>
        //       <img src="/images/bhim.png" alt="Bhim" />
        //     </li>
        //     <li>
        //       <img src="/images/g-pay.png" alt="G Pay" />
        //     </li>
        //     <li>
        //       <img src="/images/paytm.png" alt="Paytm" />
        //     </li>
        //     <li>
        //       <img src="/images/pay.png" alt="pay" />
        //     </li>
        //   </ul>
        //   <div
        //     className="payment"
        //     style={{ textAlign: "center", padding: "20px" }}
        //   ></div>
        // </div>
      }
    </div>
  );
}

export default DonateNow;
