import api from "../utils/api-strapi";

import { GET_SPONSER_UPDATES } from "../constants/Types";

// Load User
export const getUpdates = () => async (dispatch) => {
  try {
    const res = await api.get("/sponser-child-updates");

    dispatch({
      type: GET_SPONSER_UPDATES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
