import { GET_BANNERS, BANNERS_ERROR } from "../constants/Types";

const initialState = {
  banners: [],
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BANNERS:
      return {
        ...state,
        banners: payload,
        loading: false,
      };
    case BANNERS_ERROR:
      return {
        ...state,
        error: "Something Went Wrong",
        loading: false,
      };

    default:
      return state;
  }
}
