import { combineReducers } from "redux";

import projects from "./projects";
import banners from "./banners";
import content from "./contents";
import causes from "./current_benifiary";
import stories from "./success-story";
import gallery from "./gallery";
import testimonials from "./testimonials";
import calams from "./national_calamities";
import rehabs from "./rehabs";
import champions from "./champions";
import press from "./press_release";
import blog from "./blog";
import volunteer from "./volunteer";
import donate from "./donate";
import teams from "./teams";
import sponser from "./sponserchild-reducer";

export default combineReducers({
  projects,
  banners,
  content,
  causes,
  stories,
  gallery,
  testimonials,
  calams,
  rehabs,
  champions,
  press,
  blog,
  volunteer,
  donate,
  teams,
  sponser,
});
