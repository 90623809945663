import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jQuery from 'jquery';
import BagDonation from './pages/BagDonation';
import SuccessCaseJayveer from './pages/SuccessCaseJayveer';
import BeneficiarieAnsh from './pages/BeneficiariAnsh';
import BeneficiaryArohi from './pages/BeneficiarieArohi';
import DonateFirebase from './pages/DonateFirebase';

const WhoWeAre = lazy(() => import('./pages/WhoWeAre'));
const Home = lazy(() => import('./pages/Home'));
const OurApproach = lazy(() => import('./pages/OurApproach'));
const Goals = lazy(() => import('./pages/Goals'));
const Awards = lazy(() => import('./pages/Awards'));
const Legal = lazy(() => import('./pages/Legal'));
const Project = lazy(() => import('./pages/Project'));
const Terms = lazy(() => import('./pages/Terms'));
const Privacy = lazy(() => import('./pages/Privacy'));
const CancellationPolicy = lazy(() => import('./pages/CancellationPolicy'));
const NationalCalamities = lazy(() => import('./pages/NationalCalamities'));
const SingleCalamities = lazy(() => import('./pages/SingleCalamities'));
const Rehabs = lazy(() => import('./pages/Rehabs'));
const SingleRehab = lazy(() => import('./pages/SingleRehab'));
const Champions = lazy(() => import('./pages/Champions'));
const Benifieries = lazy(() => import('./pages/Benifieries'));

const Stories = lazy(() => import('./pages/Stories'));
const SingleBenificiery = lazy(() => import('./pages/SingleBenificiery'));
const SingleStory = lazy(() => import('./pages/SingleStory'));
// const SuccessCaseJayveer = lazy(() => import("./pages/SuccessCaseJayveer"));
const SingleChampion = lazy(() => import('./pages/SingleChampion'));
const BecomeAVolunteer = lazy(() => import('./pages/BecomeAVolunteer'));
const Blog = lazy(() => import('./pages/Blog'));
const SinglePost = lazy(() => import('./pages/SinglePost'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Donate = lazy(() => import('./pages/Donate'));
const PressRelease = lazy(() => import('./pages/PressRelease'));
const Events = lazy(() => import('./pages/Events'));
const SingleEvent = lazy(() => import('./pages/SingleEvent'));
const Testimonials = lazy(() => import('./pages/Testimonials'));
const DonationsPage = lazy(() => import('./pages/DonationsPage'));
const ECSTerms = lazy(() => import('./pages/ECSTerms'));
const DonateNowProject = lazy(() => import('./components/DonateNowProject'));
const DonateProject = lazy(() => import('./components/donation/DonateProject'));
const DonateCalam = lazy(() => import('./components/donation/DonateCalam'));
const DonateRehab = lazy(() => import('./components/donation/DonateRehab'));
const OurTeam = lazy(() => import('./pages/OurTeam'));
const SponserAChild = lazy(() => import('./pages/SponserAChild'));
const DonateChild = lazy(() => import('./components/donation/DonateChild'));
const Founders = lazy(() => import('./pages/Founders'));
const BankDetails = lazy(() => import('./pages/BankDetails'));
const WhereWeWork = lazy(() => import('./pages/WhereWeWork'));
const Financial = lazy(() => import('./pages/Financial'));
const Gallery = lazy(() => import('./pages/Gallery'));
const ThankYou = lazy(() => import('./pages/ThankYou'));
const OxygenRequestForm = lazy(() => import('./pages/OxygenRequestForm'));
const renderLoader = () => (
  <img
    src='/images/spinner.gif'
    style={{ width: '200px', margin: 'auto', display: 'block' }}
    alt='Loading...'
  />
);
function Routes() {
  return (
    <Suspense fallback={renderLoader()}>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route
          exact
          path='/oxygen-request-form'
          component={OxygenRequestForm}
        />
        <Route exact path='/who-we-are' component={WhoWeAre} />
        <Route exact path='/our-team' component={OurTeam} />
        <Route exact path='/our-approach' component={OurApproach} />
        <Route exact path='/legal' component={Legal} />
        <Route exact path='/founders' component={Founders} />
        <Route exact path='/bank-details' component={BankDetails} />
        <Route exact path='/where-we-work' component={WhereWeWork} />
        <Route exact path='/financials' component={Financial} />

        <Route exact path='/project/:slug' component={Project} />
        <Route exact path='/terms-and-conditions' component={Terms} />
        <Route exact path='/ECS-terms-and-conditions' component={ECSTerms} />

        <Route exact path='/privacy-policy' component={Privacy} />
        <Route
          exact
          path='/cancellation-policy'
          component={CancellationPolicy}
        />
        <Route
          exact
          path='/national-calamities'
          component={NationalCalamities}
        />
        <Route
          exact
          path='/national-calamities/:slug'
          component={SingleCalamities}
        />
        <Route exact path='/rehabs' component={Rehabs} />

        <Route exact path='/rehabs/:slug' component={SingleRehab} />
        <Route exact path='/champions' component={Champions} />
        <Route exact path='/champions/:slug' component={SingleChampion} />
        <Route exact path='/current-beneficiaries' component={Benifieries} />
        {/* <Route
          exact
          path='/current-beneficiaries/ansh-kumar'
          component={BeneficiarieAnsh}
        /> */}
        {/* <Route
          exact
          path='/current-beneficiaries/arohi-kumari'
          component={BeneficiaryArohi}
        /> */}
        <Route
          exact
          path='/current-beneficiaries/:slug'
          component={SingleBenificiery}
        />

        <Route exact path='/success-stories' component={Stories} />
        {/* <Route
          exact
          path='/success-stories/jayveer'
          component={SuccessCaseJayveer}
        /> */}
        <Route exact path='/success-stories/:slug' component={SingleStory} />

        <Route exact path='/become-a-volunteer' component={BecomeAVolunteer} />
        <Route exact path='/blog' component={Blog} />
        <Route exact path='/blog/:slug' component={SinglePost} />
        <Route exact path='/contact-us' component={ContactUs} />
        {/* <Route exact path='/donate' component={Donate} /> */}
        <Route exact path='/donate' component={DonateFirebase} />
        <Route exact path='/sponsor-a-child' component={SponserAChild} />
        <Route exact path='/bag-donation' component={BagDonation} />
        <Route exact path='/donate-project' component={DonateProject} />
        <Route exact path='/donate-calamities' component={DonateCalam} />
        <Route exact path='/donate-rehabs' component={DonateRehab} />
        <Route exact path='/donate-student' component={DonateChild} />
        <Route exact path='/donation' component={DonationsPage} />
        <Route exact path='/press-release' component={PressRelease} />
        <Route exact path='/awards-and-recognition' component={Awards} />
        <Route exact path='/events' component={Events} />
        <Route exact path='/gallery' component={Gallery} />
        <Route exact path='/thank-you' component={ThankYou} />
        <Route exact path='/events/:slug' component={SingleEvent} />
        <Route exact path='/testimonials' component={Testimonials} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
