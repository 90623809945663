import {
  GET_REHAB,
  REHAB_ERROR,
  REHAB_SINGLE,
  GET_SINGLE_REHAB,
} from "../constants/Types";

const initialState = {
  rehabs: [],
  loading: true,
  error: {},
  rehab: [],
  rehabById: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_REHAB:
      return {
        ...state,
        rehabs: payload,
        loading: false,
      };
    case REHAB_ERROR:
      return {
        ...state,
        error: "Something Went Wrong",
        loading: false,
      };
    case REHAB_SINGLE:
      return {
        ...state,
        rehab: payload,
        loading: false,
      };
    case GET_SINGLE_REHAB:
      return {
        ...state,
        rehabById: payload,
        loading: false,
      };

    default:
      return state;
  }
}
