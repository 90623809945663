import {
  GET_CURRENT_BENIFICIARY,
  BENIFICIARY_ERROR,
  GET_SINGLE_BENIFICIARY,
  CLEAR_SINGLE_BENIFICIARY,
} from "../constants/Types";

const initialState = {
  causes: [],
  loading: true,
  error: {},
  cause: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CURRENT_BENIFICIARY:
      return {
        ...state,
        causes: payload,
        loading: false,
      };
    case BENIFICIARY_ERROR:
      return {
        ...state,
        error: "Something Went Wrong",
        loading: false,
      };
    case GET_SINGLE_BENIFICIARY:
      return {
        ...state,
        cause: payload,
        loading: false,
      };
    case CLEAR_SINGLE_BENIFICIARY:
      return {
        ...state,
        cause: null,
        loading: true,
      };

    default:
      return state;
  }
}
