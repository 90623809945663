import {
  GET_SUCCESS_STORIES,
  STORIES_ERROR,
  GET_SUCCESS_STORY,
} from "../constants/Types";

const initialState = {
  stories: [],
  loading: true,
  error: {},
  story: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SUCCESS_STORIES:
      return {
        ...state,
        stories: payload,
        loading: false,
      };
    case GET_SUCCESS_STORY:
      return {
        ...state,
        story: payload,
        loading: false,
      };
    case STORIES_ERROR:
      return {
        ...state,
        error: "Something Went Wrong",
        loading: false,
      };

    default:
      return state;
  }
}

