import { GET_SPONSER_UPDATES } from "../constants/Types";

const initialState = {
  loading: true,
  updates: [],
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SPONSER_UPDATES:
      return {
        ...state,
        updates: payload,
        loading: false,
      };

    default:
      return state;
  }
}
