import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Wrapper from '../components/Wrapper';
import Breadcumb from '../components/Breadcumb';
import Header from '../components/Header';
import renderHtml from 'react-render-html';
import { useLocation } from 'react-router-dom';
import { singleCurrentBenificiaryByID } from '../action/current_benificiary';
import { addDonation } from '../action/donation_action';
import { URI } from '../utils/constants';
import axios from 'axios';
import { db } from '../utils/config';
import { Timestamp, addDoc, collection } from 'firebase/firestore';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Donate({
  singleCurrentBenificiaryByID,
  history,
  causes: { cause },
  donate,
  addDonation,
}) {
  let query = useQuery();
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [amount, setAmount] = useState(query.get('amount'));
  const [tip, setTip] = useState('15');
  const [tipAmount, setTipAmount] = useState(
    parseFloat(query.get('amount') * 0.15)
  );
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('91');
  const [phone, setPhone] = useState('');
  const [donateAnonymously, setDonateAnonymously] = useState(false);
  const [someoneBehalf, setSomeoneBehalf] = useState(false);
  const [donorName, setDonorName] = useState('');
  const [donorNote, setDonorNote] = useState('');
  const [name_error, setName_error] = useState(false);
  const [amount_error, setamount_error] = useState(false);
  const [phone_error, setphone_error] = useState(false);
  const [email_error, setemail_error] = useState(false);
  const [id, setid] = useState(query.get('benificiary'));
  const [isLoading, setIsLoading] = useState(false);
  const [pan, setPan] = useState('');
  const [address, setAddress] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log(window.document.referrer);
  useEffect(() => {
    if (id) {
      singleCurrentBenificiaryByID(id);
    } else {
      history.push('/current-beneficiaries');
    }
  }, [singleCurrentBenificiaryByID, id, history]);
  const makeDonation = async () => {
    console.log('clikced');

    if (name === '') {
      setName_error(true);
      return;
    }
    if (amount === '') {
      setamount_error(true);
      return;
    }
    if (phone === '') {
      setphone_error(true);
      return;
    }
    if (email === '') {
      setemail_error(true);
      return;
    }
    if (!name_error && !amount_error && !phone_error && !email_error) {
      setIsLoading(true);
      const totalAmount = parseFloat(amount) + parseFloat(tipAmount);
      // addDonation(formData, cause[0].razorpay_link);
      const order = await axios.get(
        `https://nationalngo.net//razorpay-single/pay.php?amount=${totalAmount}`
      );
      console.log(order.data);

      var options = {
        key: 'rzp_live_YjsybpU5dNZ5B7', // Enter the Key ID generated from the Dashboard
        amount: totalAmount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: 'INR',
        name: 'National NGO',
        description: '',
        image: 'https://nationalngo.org/images/logo.png',
        order_id: order.data, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          const newForm = {
            Amount: amount,
            currency: 'INR',
            name: name,
            email: email,

            phone: phone,
            anonymous: donateAnonymously,
            donor_name: donorName,
            doner_note: donorNote,
            current_beneficiary: id,
          };
          const postData = {
            donor_name: name,
            phone: phone,
            email: email,
            pan: pan,
            amount: amount.toString(),
            tip: tipAmount,
            address: address,

            message: donorNote,
            transaction_id: response.razorpay_payment_id,

            page: id ? id : 'donate-now',

            referrer: window.document.referrer,
          };

          // await axios.post(`https://api.nationalngo.net/donations`, postData);
          const donationsRef = collection(db, 'netdonations');
          postData.createdAt = Timestamp.fromDate(new Date());
          const docRef = await addDoc(donationsRef, postData);

          window.location.href = `/thank-you?amount=${totalAmount}`;
        },
        prefill: {
          name: name,
          email: email,
          contact: phone,
        },
        theme: {
          color: '#3399cc',
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on('payment.failed', function (response) {
        alert('Payment Failed Please try again');
      });
      rzp1.open();

      setIsLoading(false);
    } else {
    }
  };

  return (
    <Fragment>
      {/* <Breadcumb heading="Donate"></Breadcumb> */}
      {cause && (
        <Fragment>
          <section className='donate-form'>
            <div className=''>
              <div className=''>
                <div className='menu'>
                  <div className='menu_design'>
                    <button
                      onClick={() => history.goBack()}
                      className='go-back'
                    >
                      {' '}
                      Go Back{' '}
                    </button>
                    <p
                      style={{
                        padding: '10px 20px',
                        fontWeight: 'bold',
                        color: '#333',
                      }}
                    >
                      {' '}
                      Donate Now{' '}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: deviceWidth > 600 ? 'wrap' : 'wrap-reverse',
                    padding: deviceWidth > 600 ? '100px 120px' : '60px 10px',
                  }}
                >
                  <div style={{ flex: 2 }}>
                    <h3>Provide Details </h3>
                    <div style={{ padding: '20px 40px 20px 10px' }}>
                      <div
                        className='form-group'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ flex: 4 }}>
                          <label> Amount </label>
                          <input
                            type='number'
                            id='fname'
                            className='form-control'
                            name='fname'
                            value={amount}
                            min={1}
                            onChange={(e) => {
                              setAmount(e.target.value);
                              setTipAmount(
                                parseFloat(
                                  e.target.value * (tip / 100)
                                ).toFixed(2)
                              );
                            }}
                            style={{ width: '90%' }}
                          />
                          {amount_error && (
                            <p className='text-danger'>Required</p>
                          )}
                        </div>
                      </div>
                      <div
                        className='form-group'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          background: '#f1f1f1',
                          padding: '20px 20px',
                          marginRight: '120px',
                          width: '90%',
                        }}
                      >
                        <div style={{ flex: 4 }}>
                          <p>
                            National NGO charges NO fees. We rely on donors like
                            you to cover for our expenses. Kindly consider a
                            tip. Thank you 🙏
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <label> Tip </label>
                            </div>
                            <div>
                              <select
                                name='tip'
                                id='tip'
                                className='form-control'
                                value={tip}
                                onChange={(e) => {
                                  setTip(e.target.value);
                                  if (e.target.value !== 'other') {
                                    setTipAmount(
                                      parseFloat(
                                        amount * (e.target.value / 100)
                                      ).toFixed(2)
                                    );
                                  }
                                }}
                              >
                                <option value='8'>
                                  8% ({parseFloat(amount * 0.8).toFixed(2)})
                                </option>
                                <option value='10'>
                                  10% ({parseFloat(amount * 0.1).toFixed(2)})
                                </option>
                                <option value='15'>
                                  15% ({parseFloat(amount * 0.15).toFixed(2)})
                                </option>
                                <option value='other'>Other</option>
                              </select>
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              <p> Tip Amount </p>
                            </div>
                            <div>
                              {' '}
                              <p>
                                {' '}
                                {tip === 'other' ? (
                                  <>
                                    <input
                                      type='number'
                                      value={tipAmount}
                                      min={0}
                                      className='form-control'
                                      onChange={(e) => {
                                        setTipAmount(e.target.value);
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>₹{tipAmount}</>
                                )}{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{ flex: 1, paddingRight: '10px' }}
                          className='form-group'
                        >
                          <label> Name </label>
                          <input
                            type='text'
                            id='fname'
                            name='fname'
                            className='form-control'
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                              setName_error(false);
                            }}
                            style={{ width: '90%' }}
                          />
                          {name_error && (
                            <p className='text-danger'>Required</p>
                          )}
                        </div>
                        <div
                          style={{ flex: 1, paddingRight: '20px' }}
                          className='form-group'
                        >
                          <label> Email </label>
                          <input
                            type='text'
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setemail_error(false);
                            }}
                            className='form-control'
                            style={{ width: '90%' }}
                          />
                          {email_error && (
                            <p className='text-danger'>Required</p>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ flex: 4 }}>
                          <label> Phone </label>
                          <input
                            type='text'
                            className='form-control'
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                              setphone_error(false);
                            }}
                            style={{ width: '90%' }}
                          />
                          {phone_error && (
                            <p className='text-danger'>Required</p>
                          )}
                        </div>
                        <div style={{ flex: 4 }}>
                          <label> PAN No(optional) </label>
                          <input
                            type='text'
                            className='form-control'
                            value={pan}
                            onChange={(e) => {
                              setPan(e.target.value);
                            }}
                            style={{ width: '90%' }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          border: '1px solid #f1f1f1',
                          background: '#f7f7f',
                          margin: '20px 0px',
                          width: '90%',
                          padding: '10px 20px',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            className='custom-control-label'
                            htmlFor='customSwitch1'
                          >
                            {' '}
                            Donate anonymously{' '}
                          </label>
                          <p>Your contributions will be marked anonymous</p>
                        </div>
                        <div style={{ flex: 1 }}>
                          <input
                            type='checkbox'
                            className='custom-control-input'
                            id='customSwitch1'
                            value={donateAnonymously}
                            onChange={(e) =>
                              setDonateAnonymously(!donateAnonymously)
                            }
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          border: '1px solid #f1f1f1',
                          background: '#f7f7f',
                          margin: '20px 0px',
                          width: '90%',
                          padding: '10px 20px',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            className='custom-control-label'
                            htmlFor='customSwitch2'
                          >
                            Donate on someone else's behalf
                          </label>
                        </div>
                        <div style={{ flex: 1 }}>
                          <input
                            type='checkbox'
                            className='custom-control-input'
                            id='customSwitch2'
                            value={someoneBehalf}
                            onChange={(e) => setSomeoneBehalf(!someoneBehalf)}
                          />
                        </div>
                        {someoneBehalf && (
                          <div style={{ flex: 2, flexWrap: 'wrap' }}>
                            <div style={{ flex: 4 }}>
                              <label> Donor Name </label>
                              <input
                                className='form-control'
                                value={donorName}
                                onChange={(e) => setDonorName(e.target.value)}
                                style={{ width: '90%' }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        <label>Address (optional) </label>
                        <textarea
                          className='form-control'
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          style={{ width: '90%' }}
                        ></textarea>
                      </div>
                      <div>
                        <label>Drop a Note</label>
                        <textarea
                          className='form-control'
                          value={donorNote}
                          onChange={(e) => setDonorNote(e.target.value)}
                          style={{ width: '90%' }}
                        ></textarea>
                      </div>
                      <div style={{ display: 'flex' }}>
                        {!isLoading && (
                          <button
                            style={{
                              padding: '10px 30px',
                              background: 'rgb(251, 188, 9)',
                              color: 'rgb(51, 51, 51)',
                              fontSize: 16,
                              borderRadius: 20,
                              marginTop: '20px',
                            }}
                            onClick={() => makeDonation()}
                          >
                            Continue to pay ₹ {amount}
                          </button>
                        )}
                        {isLoading && (
                          <button
                            style={{
                              padding: '10px 30px',
                              background: 'rgb(251, 188, 9)',
                              color: 'rgb(51, 51, 51)',
                              fontSize: 16,
                              borderRadius: 20,
                              marginTop: '20px',
                            }}
                          >
                            Loading ......
                          </button>
                        )}

                        <button
                          style={{
                            padding: '10px 30px',
                            fontWeight: 'bold',
                            color: 'rgb(51, 51, 51)',
                            fontFamily: 'Roboto-light',
                            borderRadius: 20,
                            fontSize: '16px',
                            marginTop: '20px',
                          }}
                        >
                          {' '}
                          Discard{' '}
                        </button>
                      </div>
                    </div>
                  </div>
                  {cause.map((item) => {
                    return (
                      <div
                        style={{
                          flex: 1,
                          borderLeft:
                            deviceWidth > 600 ? '1px solid #f1f1f1' : 'none',
                          padding: '20px',
                        }}
                      >
                        <p
                          className='text-center'
                          style={{
                            padding: '10px',
                            fontWeight: 'bold',
                            color: '#333',
                          }}
                        >
                          {' '}
                          You are donating towards{' '}
                        </p>
                        <div className='text-center '>
                          <img
                            src={`${item.profile_photo}`}
                            alt='Someone'
                            style={{ height: '100px', width: 'auto' }}
                          />
                        </div>

                        <p
                          className='text-center'
                          style={{
                            fontWeight: 'bold',
                            color: '#333',
                            paddingTop: '20px',
                          }}
                        >
                          {item.name}
                        </p>
                        {deviceWidth > 600 && (
                          <Fragment>
                            <p style={{ padding: '30px  20px' }}>
                              {renderHtml(item.content)}
                            </p>
                            <div
                              style={{
                                display: 'flex',
                                background: '#f1f1f1',
                                justifyContent: 'space-between',
                                padding: '5px 10px',
                                textAlign: 'center',
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <i className='fa fa-user'></i> Benificiary
                              </div>
                              <div style={{ flex: 1 }}>
                                {item.name}
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  causes: state.causes,
  donate: state.donate,
});

export default connect(mapStateToProps, {
  singleCurrentBenificiaryByID,
  addDonation,
})(Donate);
