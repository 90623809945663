import React from 'react'
import Header from '../components/Header'
import Wrapper from '../components/Wrapper'
import Breadcumb from '../components/Breadcumb'
import { Link } from 'react-router-dom/cjs/react-router-dom'


function SuccessCaseJayveer() {
    return (
        <>
            <Wrapper>
                <Header />
                <Breadcumb heading="Success Stories: Jayveer" />
                <section className="success-case-view ptb-45">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="success-case-box">
                                    <div className="success-case-img">
                                        <img src="/assets/images/jayveer_success_case.jpg" alt="" />
                                    </div>
                                    <div className="success-case-box-content">
                                        <div className="success-case-content-title">
                                            <h4 className="title">
                                                Jayveer ((15 Years))
                                            </h4>
                                        </div>
                                        <div className="success-case-content-disease">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    fontWeight: "bold",
                                                    padding: "1rem 0rem",
                                                }}
                                            >
                                                <div>
                                                    <span>
                                                        <Link
                                                            to={`/`}
                                                        >
                                                            <i className="fa fa-plus"></i> Blood Cancer
                                                        </Link>
                                                    </span>
                                                </div>
                                                {
                                                    // <div>
                                                    //     <Link
                                                    //         to={`/`}
                                                    //     >
                                                    //         <i className="fa fa-map-marker" /> MEDICAL DOCUMENTS{" "}
                                                    //     </Link>
                                                    // </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="success-case-content-para">
                                            <p>15 years old Jayveer Singh is an innocent boy who has been diagnosed with Acute Lymphoblastic Leukemia His father has never been this much happy before Jayveer was born. He has been working hard to fulfill his son’s every wish but an unfortunate news has made him worried.</p>

                                            <p>
                                                He has been found suffering from Acute Lymphoblastic Leukemia (ALL) is the most common type of cancer in children. It affects certain cells in the immune system, called B cells and T cells.
                                            </p>

                                            <p> ALL usually affects B cells in children. (ALL) makes the child more likely to get infections, because he doesn't have the protection of those B cells. His father earns bread and butter for his family. But he is unable to meet the treatment cost through this meagre income. Thus, NOSE knowing…</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Wrapper>
        </>
    )
}

export default SuccessCaseJayveer