import React from "react";
import { connect } from "react-redux";
import Wrapper from "../components/Wrapper";
import Header from "../components/Header";
import DonateNow from "../components/DonateNow";
import BagDonationSidebar from "../components/BagDonationSidebar";
import SingleSponser from "../components/SingleSponser";



function BagDonation() {
    return (
        <Wrapper>
            <Header defineBackground={true} />
            <section className="style-2" style={{ backgroundColor: "#f8f9f6" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <SingleSponser />
                            {window.innerWidth > 700 && <BagDonationSidebar />}
                        </div>

                        <div className="col-md-4">
                            <BagDonationSidebar />
                            <div
                                style={{
                                    background: "#fff",
                                    padding: "10px",
                                    marginBottom: "10px",
                                }}
                                className="selector"
                            >
                                <p>
                                    {" "}
                                    If you want to help a schoolkid in your neighborhood and beyond, a huge thank you to all of you who buy bags for our children at The National NGO.
                                </p>
                                <p>
                                    {" "}
                                    Help our children walk to school in style. Give them stuff to carry all that weight on their shoulders.
                                </p>
                                <p>
                                    {" "}
                                    Without any support from you people we wouldn’t be able to equally uplift our kid’s education. If you love us like we love our kids and know how much it means to them then please do make a donation today!
                                </p>
                            </div>
                            <div
                                className="selector"
                                style={{ background: "#fff", padding: "10px" }}
                            >
                                <h3> OUR IMPACT</h3>
                                <p>
                                    With this wonderful initiative, you can help them achieve another dream — looking like other students.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
}

const mapStateToProps = (state) => ({
    content: state.content,
});

export default connect(mapStateToProps, {})(BagDonation);