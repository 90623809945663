import api from "../utils/api-strapi";
import {
  GET_PROJECTS,
  PROJECTS_ERROR,
  GET_SINGLE_PROJECT,
  PROJECT_ERROR,
} from "../constants/Types";
import docs from "../models/projects.json";
// Load User
export const loadProjects = () => async (dispatch) => {
  try {
    const res = await api.get("/projects");

    dispatch({
      type: GET_PROJECTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROJECTS_ERROR,
    });
  }
};

// Load User
export const singleProjectByID = (id) => async (dispatch) => {
  try {
    const filteredData = docs.filter((item) => item._id === id);
    dispatch({
      type: GET_SINGLE_PROJECT,
      payload: filteredData,
    });
  } catch (err) {
    dispatch({
      type: PROJECT_ERROR,
    });
  }
};
