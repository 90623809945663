import React, { useState, useEffect, Fragment } from 'react';
import Header from '../components/Header';
import Wrapper from '../components/Wrapper';
import Breadcumb from '../components/Breadcumb';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import SidebarAwards from '../components/SidebarAwards';
import SidebarMediaCoverage from '../components/SidebarMediaCoverage';
import Skeleton from 'react-loading-skeleton';
import { Helmet } from 'react-helmet';
import renderHTML from 'react-render-html';
import DonateNow from '../components/DonateNow';
import SingleCauseSidebar from '../components/SingleCauseSidebar';

function BeneficiaryArohi({ history, match }) {
    const [showTab, setshowTab] = useState('story');
    const openFacebookURL = () => {
        console.log(window.location.pathname);
        const url = `http://nationalngo.net${window.location.pathname}`;

        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
            'facebook-share-dialog',
            'width=626,height=436'
        );
    };
    return (
        <Fragment>
            <Helmet>
                <title>{'Arohi Kumari Blood Cancer'}</title>
                <meta
                    name='description'
                    content={
                        'HELP SAVE AROHI: DONATE FOR THE TREATMENT OF 3-YEAR-OLD FIGHTING BLOOD CANCER'
                    }
                />
            </Helmet>
            <Wrapper>
                <Header defineBackground={true} />
                <Fragment>
                    <section className='style-2' style={{ backgroundColor: '#f8f9f6' }}>
                        <div className='text-center'>
                            <div
                                style={{
                                    padding: '20px 0px 10px 0px',
                                    fontSize: '16px',
                                    color: '#333',
                                }}
                            >
                                "100% donation goes towards to patient treatment"
                            </div>
                        </div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <div>
                                        <div
                                            className='cause-area urgent-cause2 single-cause cause-list-bg '
                                            style={{ padding: '20px 0', backgroundColor: '#f8f9f6' }}
                                        >
                                            <article
                                                className='item clearfix'
                                                style={{
                                                    background: '#fff',
                                                    boxShadow: '5px 0px 8px #888888',
                                                    width: '100%',
                                                }}
                                            >
                                                <h3 style={{ padding: '20px 20px 0px 20px' }}>
                                                    Arohi Kumar
                                                </h3>
                                                <div style={{ padding: '20px' }}>
                                                    <a
                                                        className='fb btn'
                                                        style={{
                                                            backgroundColor: '#133783',
                                                            color: '#fff',
                                                            marginRight: 10,
                                                        }}
                                                        href='#shareonfacebook'
                                                        onClick={() => openFacebookURL()}
                                                    >
                                                        <i className='fa fa-facebook fa-fw'></i>Share{' '}
                                                        {window.innerWidth < 700 ? '' : 'on Facebook'}
                                                    </a>
                                                    <a
                                                        className='fb btn'
                                                        style={{
                                                            backgroundColor: '#25D366',
                                                            color: '#fff',
                                                        }}
                                                        href={`https://api.whatsapp.com/send?text=http://nationalngo.net${window.location.pathname}`}
                                                        data-action='share/whatsapp/share'
                                                    >
                                                        <i className='fa fa-whatsapp fa-fw'></i>Share{' '}
                                                        {window.innerWidth < 700 ? '' : 'on Whatsapp'}
                                                    </a>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        padding: '10px 10px',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: 'rgb(255, 87, 34)',
                                                            color: '#fff',
                                                            padding: '10px 10px',
                                                        }}
                                                    >
                                                        Urgent
                                                    </div>
                                                    <div
                                                        style={{
                                                            backgroundColor: '#25D366',
                                                            color: '#fff',
                                                            padding: '10px 10px',
                                                        }}
                                                    >
                                                        {' '}
                                                        <i className='fa fa-check-circle-o'></i> Verified by
                                                        National NGO{' '}
                                                    </div>
                                                </div>
                                                <figure
                                                    className='img-box1'
                                                    style={{
                                                        display: 'block',
                                                        padding: '10px 10px',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <img
                                                        src={`/images/static-case/arohi/1680781061.jpg`}
                                                        alt={'Arohi Kumar'}
                                                        style={{
                                                            width: '100%',
                                                            height: '500px',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: 20,
                                                            left: 10,
                                                            padding: '10px 20px',
                                                            fontSize: '18px',
                                                            background: 'rgb(255, 87, 34)',
                                                            color: '#fff',
                                                        }}
                                                    >
                                                        {' '}
                                                        Tax benefits{' '}
                                                    </div>
                                                </figure>
                                            </article>
                                            {/* {window.innerWidth < 700 &&
                                                <DonateNow item={item} history={history} />
                                            } */}

                                            <div id='myBtnContainer' style={{ padding: 10 }}>
                                                <button
                                                    className={showTab === 'story' ? 'btn active' : 'btn'}
                                                    onClick={() => setshowTab('story')}
                                                >
                                                    Story
                                                </button>
                                            </div>
                                            {showTab === 'story' && (
                                                <div
                                                    className='text'
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        padding: '20px',
                                                        fontSize: '1.2em',
                                                        fontWeight: 400,
                                                        color: '#666666',
                                                    }}
                                                >
                                                    <p>
                                                        <strong>
                                                            HELP SAVE AROHI: DONATE FOR THE TREATMENT OF 3-YEAR-OLD FIGHTING BLOOD CANCER
                                                        </strong>
                                                    </p>

                                                    <p>
                                                        Arohi Kumar, a sweet 3-year-old girl from Bihar, is currently battling blood cancer at AIIMS Delhi. Her father, Anikesh Kumar, is doing everything he can to save his little girl, but the cost of treatment is too high. The family needs 1.5 Lacs to pay for the treatment but cannot afford it alone.
                                                    </p>

                                                    <p>
                                                        We at National NGO are working hard to raise funds for Arohi's treatment. We understand that every penny counts and are urging people from all over the country to donate what they can to help save Arohi's life.
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            The doctors at AIIMS Delhi are doing everything they can to help her fight this disease. She is a brave little girl, and despite the pain and discomfort, she still manages to smile and play with her toys. Anikesh is constantly by her side, holding her hand and offering her words of encouragement.
                                                        </strong>
                                                    </p>

                                                    <p>
                                                        We have been spreading the word about Arohi's situation, and people are starting to come forward to help. Some are making large donations, while others are giving what they can. Every rupee counts, and we at National NGO are grateful for the generosity of those who have already donated.
                                                    </p>

                                                    <p>
                                                        Arohi's treatment is ongoing, and will be a long road ahead. But with the support of our NGO and the kindness of strangers, Anikesh is hopeful that his daughter will fully recover. He is grateful to everyone who has donated and wants to thank them from the bottom of his heart.
                                                    </p>

                                                    <p>
                                                        Together, we can make a difference in Arohi's life. With your help, we can ensure she gets the treatment she needs to beat this terrible disease. Let's unite as a community and show Arohi and her family that they are not alone.
                                                    </p>
                                                </div>
                                            )}
                                            <div className='beneficiarie-doc'>
                                                <div>
                                                    <iframe
                                                        src={`/images/static-case/arohi/arohi.pdf`}
                                                        style={{ width: '100%', height: '550px' }}
                                                        frameborder='0'
                                                    ></iframe>
                                                </div>
                                            </div>
                                            {window.innerWidth > 700 && (
                                                <DonateNow
                                                    item={{
                                                        name: 'Arohi Kumari',
                                                        slug: 'arohi',
                                                        goal: 150000,
                                                        raised_amount: 0,
                                                        donations: [],
                                                        image: '/images/static-case/arohi/1680781061.jpg',
                                                    }}
                                                    history={history}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div style={{ padding: '20px 10px' }}>
                                        <SingleCauseSidebar
                                            item={{
                                                name: 'Arohi Kumari',
                                                slug: 'arohi',

                                                donations: [],
                                                image: '/images/static-case/arohi/1680781061.jpg',
                                            }}
                                            hideGoal={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div style={{ marginTop: '40px' }}>
                                        <div className='blog-sidebar'>
                                            <SidebarAwards />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div style={{ marginTop: '40px' }}>
                                        <div className='blog-sidebar'>
                                            <SidebarMediaCoverage />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div style={{ marginTop: '40px' }}>
                                        <div className='blog-sidebar'>
                                            <h3 style={{ padding: 10 }}>Success Story</h3>

                                            <iframe
                                                width={'100%'}
                                                height={315}
                                                src='https://www.youtube.com/embed/-wnj9_M4ipw'
                                                title='YouTube video player'
                                                frameBorder={0}
                                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                allowFullScreen
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Fragment>
            </Wrapper>
        </Fragment>
    );
}

export default BeneficiaryArohi;
