export const GET_PROJECTS = "GET_PROJECTS";
export const PROJECTS_ERROR = "PROJECTS_ERROR";
export const GET_BANNERS = "GET_BANNERS";
export const BANNERS_ERROR = "BANNERS_ERROR";
export const GET_CONTENTS = "GET_CONTENTS";
export const CONTENTS_ERROR = "CONTENTS_ERROR";
export const GET_CURRENT_BENIFICIARY = "GET_CURRENT_BENIFICIARY";
export const BENIFICIARY_ERROR = "BENIFICIARY_ERROR";
export const GET_SINGLE_BENIFICIARY = "GET_SINGLE_BENIFICIARY";
export const GET_SINGLE_SUCCESS_STORY = "GET_SINGLE_SUCCESS_STORY";
export const GET_SUCCESS_STORIES = "GET_SUCCESS_STORIES";
export const STORIES_ERROR = "STORIES_ERROR";
export const GET_SUCCESS_STORY = "GET_SUCCESS_STORY";
export const GET_GALLERY = "GET_GALLERY";
export const GALLERY_ERROR = "GALLERY_ERROR";
export const GALLERY_SINGLE = "GALLERY_SINGLE";
export const GET_TESTMONIAL = "GET_TESTMONIAL";
export const TESTMONIAL_ERROR = "TESTMONIAL_ERROR";
export const GET_NATIONAL_CALAMITIES = "GET_NATIONAL_CALAMITIES";
export const NATIONAL_CALAMITIES_ERROR = "NATIONAL_CALAMITIES_ERROR";
export const NATIONAL_CALAMITIES_SINGLE = "NATIONAL_CALAMITIES_SINGLE";
export const GET_REHAB = "GET_REHAB";
export const REHAB_ERROR = "REHAB_ERROR";
export const REHAB_SINGLE = "REHAB_SINGLE";
export const GET_CHAMPION = "GET_CHAMPION";
export const CHAMPION_ERROR = "CHAMPION_ERROR";
export const CHAMPION_SINGLE = "CHAMPION_SINGLE";
export const GET_PRESS_RELEASE = "GET_PRESS_RELEASE";
export const PRESS_RELEASE_ERROR = "PRESS_RELEASE_ERROR";
export const GET_BLOG = "GET_BLOG";
export const BLOG_ERROR = "BLOG_ERROR";
export const BLOG_SINGLE = "BLOG_SINGLE";
export const ADD_VOLUNTEER = "ADD_VOLUNTEER";
export const VOLUNTEER_ERROR = "VOLUNTEER_ERROR";
export const ADD_DONATION = "ADD_DONATION";
export const DONATION_ERROR = "DONATION_ERROR";
export const CONTACT_US = "CONTACT_US";
export const GET_SINGLE_PROJECT = "GET_SINGLE_PROJECT";
export const PROJECT_ERROR = "PROJECT_ERROR";
export const GET_SINGLE_CALAM = "GET_SINGLE_CALAM";
export const GET_SINGLE_REHAB = "GET_SINGLE_REHAB";

export const GET_TEAMS = "GET_TEAMS";
export const TEAM_ERROR = "TEAM_ERROR";
export const GET_SPONSER_UPDATES = "GET_SPONSER_UPDATES";
export const CLEAR_SINGLE_BENIFICIARY = "CLEAR_SINGLE_BENIFICIARY";
export const CLEAR_SINGLE_SUCCESS_STORY = "CLEAR_SINGLE_SUCCESS_STORY";
