import api from "../utils/api-strapi";
import {
  GET_CURRENT_BENIFICIARY,
  BENIFICIARY_ERROR,
  GET_SINGLE_BENIFICIARY,
  CLEAR_SINGLE_BENIFICIARY,
} from "../constants/Types";
import causes from "../models/beneficiary.json";
// Load User
export const loadCurrentBenificiary = () => async (dispatch) => {
  try {
    // const res = await api.get(
    //   "/current-beneficiaries?type_ne=hide&_sort=createdAt:DESC"
    // );
    const filteredCauses = causes.filter((item) => item.type !== "hide");

    dispatch({
      type: GET_CURRENT_BENIFICIARY,
      payload: filteredCauses,
    });
  } catch (err) {
    dispatch({
      type: BENIFICIARY_ERROR,
    });
  }
};
// Load User
export const singleCurrentBenificiary = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_SINGLE_BENIFICIARY,
    });

    const singleCause = causes.filter((item) => item.slug === slug);
    dispatch({
      type: GET_SINGLE_BENIFICIARY,
      payload: singleCause,
    });
  } catch (err) {
    dispatch({
      type: BENIFICIARY_ERROR,
    });
  }
};
// Load User
export const singleCurrentBenificiaryByID = (id) => async (dispatch) => {
  try {
    const singleCause = causes.filter((item) => item._id === id);
    dispatch({
      type: GET_SINGLE_BENIFICIARY,
      payload: singleCause,
    });
  } catch (err) {
    dispatch({
      type: BENIFICIARY_ERROR,
    });
  }
};
