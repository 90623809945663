import {
  GET_CHAMPION,
  CHAMPION_ERROR,
  CHAMPION_SINGLE,
} from "../constants/Types";

const initialState = {
  champions: [],
  loading: true,
  error: {},
  champion: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CHAMPION:
      return {
        ...state,
        champions: payload,
        loading: false,
      };
    case CHAMPION_ERROR:
      return {
        ...state,
        error: "Something Went Wrong",
        loading: false,
      };
    case CHAMPION_SINGLE:
      return {
        ...state,
        champion: payload,
        loading: false,
      };

    default:
      return state;
  }
}
