import { GET_BLOG, BLOG_ERROR, BLOG_SINGLE } from "../constants/Types";

const initialState = {
  posts: [],
  loading: true,
  error: {},
  post: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BLOG:
      return {
        ...state,
        posts: payload,
        loading: false,
      };
    case BLOG_ERROR:
      return {
        ...state,
        error: "Something Went Wrong",
        loading: false,
      };
    case BLOG_SINGLE:
      return {
        ...state,
        post: payload,
        loading: false,
      };

    default:
      return state;
  }
}
