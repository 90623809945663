import { GET_GALLERY, GALLERY_ERROR, GALLERY_SINGLE } from "../constants/Types";

const initialState = {
  gallery: [],
  loading: true,
  error: {},
  event: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_GALLERY:
      return {
        ...state,
        gallery: payload,
        loading: false,
      };
    case GALLERY_ERROR:
      return {
        ...state,
        error: "Something Went Wrong",
        loading: false,
      };
    case GALLERY_SINGLE:
      return {
        ...state,
        event: payload,
        loading: false,
      };

    default:
      return state;
  }
}
