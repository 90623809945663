import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jQuery from "jquery";
import { connect } from "react-redux";
import { loadProjects } from "../action/projects_action";
import MobileMenu from "./MobileMenu";

function Header({ defineBackground, loadProjects, projects: { projects } }) {
  const [aboutShown, setaboutShown] = useState(false);
  const [innerWidth, setinnerWidth] = useState(window.innerWidth);
  const [projectShown, setprojectShown] = useState(false);
  const [donateShown, setdonateShown] = useState(false);
  const [contactShown, setcontactShown] = useState(false);
  const [galleryShown, setgalleryShown] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    loadProjects();
  }, [loadProjects]);
  console.log("Width of device ", innerWidth);
  const closeMenu = () => {
    // console.log("check for change");
    setIsMenuOpen(false);
    console.log("check for change", isMenuOpen);
  };
  if (innerWidth > 745) {
    return (
      <section
        className={
          defineBackground
            ? `theme_menu backgound-shadow`
            : `theme_menu stricky`
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="main-logo">
                <Link to="/">
                  <img
                    src={
                      defineBackground
                        ? `/images/logo-dark.png`
                        : `/images/logo-fotr.png`
                    }
                    alt="National NGO"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-9 menu-column">
              {/* id="main_menu" */}
              <nav className="defaultmainmenu">
                <ul
                  className={
                    defineBackground
                      ? `defaultmainmenu-menu changemenu`
                      : `defaultmainmenu-menu`
                  }
                >
                  <li
                    className="active"
                    onMouseEnter={() => setaboutShown(true)}
                    onMouseLeave={() => setaboutShown(false)}
                  >
                    <a href="#">About Us</a>
                    <ul
                      className="dropdown"
                      style={{ display: aboutShown ? "block" : "none" }}
                    >
                      <li>
                        <Link to="/who-we-are">Who We Are</Link>
                      </li>
                      <li>
                        <Link to="/our-approach">Our Approach</Link>
                      </li>
                      <li>
                        <Link to="/founders">Founders</Link>
                      </li>
                      <li>
                        <Link to="/our-team">Our Team </Link>
                      </li>
                      <li>
                        <Link to="/awards-and-recognition">
                          Awards & Recognition{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/financials">Financials </Link>
                      </li>
                      <li>
                        <Link to="/bank-details">Bank Details </Link>
                      </li>
                      <li>
                        <Link to="/legal">Legal </Link>
                      </li>
                      <li>
                        <Link to="/where-we-work">Where we work </Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className="active"
                    onMouseEnter={() => setprojectShown(true)}
                    onMouseLeave={() => setprojectShown(false)}
                  >
                    <a href="#">Projects</a>
                    <ul
                      className="dropdown"
                      style={{ display: projectShown ? "block" : "none" }}
                    >
                      <li>
                        <Link to="/project/bag-donation">Bag Donation</Link>
                      </li>
                      <li>
                        <Link to="/project/cure-india">Cure India</Link>
                      </li>
                      <li>
                        <Link to="/project/sewa-bhawan">Sewa Bhawan</Link>
                      </li>
                      {/* <li>
                        <Link to="/project/covid-19-relief">
                          Covid 19 Relief
                        </Link>
                      </li> */}

                      <li>
                        <Link to="/project/national-rehabilitation-center">
                          National Rehabilitation Center
                        </Link>
                      </li>
                      <li>
                        <Link to="/project/national-ngo-kitchen">
                          National NGO Kitchen
                        </Link>
                      </li>

                      <li>
                        <Link to="/national-calamities">Calamities Relief</Link>
                      </li>

                      <li>
                        <Link to="/project/other-relief-and-distribution-camps">
                          Other Relief & distribution Camps
                        </Link>
                      </li>
                      <li>
                        <Link to="/project/mobile-school">Mobile School</Link>
                      </li>
                      <li>
                        <Link to="/project/national-training-center">
                          National Training Center
                        </Link>
                      </li>
                      <li>
                        <Link to="/project/national-public-school">
                          National Public School
                        </Link>
                      </li>
                      <li>
                        <Link to="/project/project-udaan">Project Udaan</Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className="active"
                    onMouseEnter={() => setgalleryShown(true)}
                    onMouseLeave={() => setgalleryShown(false)}
                  >
                    <Link to="/gallery">Gallery</Link>
                    <ul
                      className="dropdown"
                      style={{ display: galleryShown ? "block" : "none" }}
                    >
                      <li>
                        <Link to="/press-release">Press</Link>
                      </li>
                      <li>
                        <Link to="/events">Events</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/become-a-volunteer">Join Us</Link>
                  </li>
                  {/* <li>
                    <Link to="/press-release">News</Link>
                  </li> */}
                  <li
                    onMouseEnter={() => setdonateShown(true)}
                    onMouseLeave={() => setdonateShown(false)}
                  >
                    <Link to="/donation">Donate</Link>
                  </li>
                  <li
                    onMouseEnter={() => setcontactShown(true)}
                    onMouseLeave={() => setcontactShown(false)}
                  >
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </nav>
            </div>
            {/* <div className="right-column">
              <div className="nav_side_content">
                <ul className="social-icon">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-google-plus" />
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="theme_menu stricky">
        <div className="container">
          <div className="row">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div className="main-logo" style={{ paddingLeft: 10 }}>
                  <Link to="/">
                    <img src="/images/logo-fotr.png" alt="National NGO" />
                  </Link>
                </div>
              </div>
              <div
                style={{
                  paddingBottom: 35,
                  paddingTop: 25,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <div style={{}}>
                  <button
                    onClick={() => {
                      console.log();
                      setIsMenuOpen(true);
                      console.log("Btn cicked", isMenuOpen);
                    }}
                  >
                    <i
                      className="fa fa-bars"
                      style={{
                        color: "#fff",
                        fontSize: "22px",
                        paddingRight: "10px",
                      }}
                    ></i>
                  </button>
                </div>

                {/* id="main_menu" */}
                {isMenuOpen && (
                  <MobileMenu
                    projects={projects}
                    isMenuOpen={isMenuOpen}
                    closeMenu={closeMenu}
                  ></MobileMenu>
                )}
              </div>
            </div>

            {/* <div className="right-column">
              <div className="nav_side_content">
                <ul className="social-icon">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-google-plus" />
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  projects: state.projects,
});

export default connect(mapStateToProps, { loadProjects })(Header);
