import { GET_PRESS_RELEASE, PRESS_RELEASE_ERROR } from "../constants/Types";

const initialState = {
  press: [],
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRESS_RELEASE:
      return {
        ...state,
        press: payload,
        loading: false,
      };
    case PRESS_RELEASE_ERROR:
      return {
        ...state,
        error: "Something Went Wrong",
        loading: false,
      };

    default:
      return state;
  }
}
