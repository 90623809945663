import React, { useState } from "react";
import { Link } from "react-router-dom";

function MobileMenu({ projects, isMenuOpen, closeMenu }) {
  const [aboutShown, setaboutShown] = useState(false);
  const [menuOpen, setMenuOpen] = useState(isMenuOpen);
  const [projectShown, setprojectShown] = useState(false);
  const [donateShown, setdonateShown] = useState(false);
  const [contactShown, setcontactShown] = useState(false);
  const [galleryShown, setgalleryShown] = useState(false);
  console.log("Menu Ope");
  return (
    <nav
      className=""
      style={{
        display: menuOpen ? "block" : "none",
        overflow: "scroll",
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100%",
        background: "#fff",
        zindex: 9999,
      }}
    >
      <button
        onClick={() => closeMenu()}
        style={{
          background: "#ff5722",
          position: "relative",
          color: "#fff",
          borderRadius: "2px",
          float: "left",
          padding: "5px 10px",
          margin: "10px",
          fontSize: "24px",
        }}
      >
        {" "}
        X{" "}
      </button>
      <ul className="mobile-menu">
        <li onClick={() => setMenuOpen(!menuOpen)}>
          <Link to="/">Home</Link>
        </li>
        <li
          className="active"
          onClick={() => {
            setaboutShown(!aboutShown);
            setprojectShown(false);
            setgalleryShown(false);
          }}
        >
          <a to="#">About Us</a>
          <ul
            onClick={() => setMenuOpen(!menuOpen)}
            style={{ display: aboutShown ? "block" : "none" }}
          >
            <li>
              <Link to="/who-we-are">Who We Are</Link>
            </li>
            <li>
              <Link to="/our-approach">Our Approach</Link>
            </li>
            <li>
              <Link to="/founders">Founders</Link>
            </li>
            <li>
              <Link to="/our-team">Our Team </Link>
            </li>
            <li>
              <Link to="/awards-and-recognition">Awards & Recognition </Link>
            </li>
            <li>
              <Link to="/financials">Financials </Link>
            </li>
            <li>
              <Link to="/bank-details">Bank Details </Link>
            </li>
            <li>
              <Link to="/legal">Legal </Link>
            </li>
            <li>
              <Link to="/where-we-work">Where we work </Link>
            </li>
          </ul>
        </li>
        <li
          className="active"
          onClick={() => {
            setaboutShown(false);
            setprojectShown(!projectShown);
            setgalleryShown(false);
          }}
        >
          <a href="#">Projects</a>
          <ul
            onClick={() => setMenuOpen(!menuOpen)}
            style={{ display: projectShown ? "block" : "none" }}
          >
            <li>
              <Link to="/project/cure-india">Cure India</Link>
            </li>
            <li>
              <Link to="/project/sewa-bhawan">Sewa Bhawan</Link>
            </li>

            <li>
              <Link to="/project/national-rehabilitation-center">
                National Rehabilitation Center
              </Link>
            </li>
            {/* <li>
              <Link to="/project/covid-19-relief">Covid 19 Relief</Link>
            </li> */}
            <li>
              <Link to="/project/national-ngo-kitchen">
                National NGO Kitchen
              </Link>
            </li>

            <li>
              <Link to="/national-calamities">Calamities Relief</Link>
            </li>

            <li>
              <Link to="/project/other-relief-and-distribution-camps">
                Other Relief & distribution Camps
              </Link>
            </li>
            <li>
              <Link to="/project/mobile-school">Mobile School</Link>
            </li>
            <li>
              <Link to="/project/national-training-center">
                National Training Center
              </Link>
            </li>
            <li>
              <Link to="/project/national-public-school">
                National Public School
              </Link>
            </li>
          </ul>
        </li>

        <li
          className="active"
          onClick={() => {
            setaboutShown(false);
            setprojectShown(false);
            setgalleryShown(!galleryShown);
          }}
        >
          <button href="#">Gallery</button>
          <ul
            onClick={() => setMenuOpen(!menuOpen)}
            style={{ display: galleryShown ? "block" : "none" }}
          >
            <li>
              <Link to="/press-release">Press</Link>
            </li>
            <li>
              <Link to="/events">Events</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/become-a-volunteer">Join Us</Link>
        </li>
        <li>
          <Link to="/press-release">News</Link>
        </li>

        <li
          onMouseEnter={() => setdonateShown(true)}
          onMouseLeave={() => setdonateShown(false)}
        >
          <Link to="/donate">Donate</Link>
        </li>
        <li
          onMouseEnter={() => setcontactShown(true)}
          onMouseLeave={() => setcontactShown(false)}
        >
          <Link to="/contact-us">Contact Us</Link>
        </li>
      </ul>
    </nav>
  );
}

export default MobileMenu;
