import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { connect } from "react-redux";
import { URI } from "../utils/constants";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import renderHTML from "react-render-html";

import SponserChildSidebar from "./SponserChildSidebar";
import { getUpdates } from "../action/sponserchild_action";

function SingleSponser({ getUpdates, sponser: { updates } }) {
  const [showTab, setshowTab] = useState("story");
  useEffect(() => {
    getUpdates();
  }, []);
  const openFacebookURL = () => {
    console.log(window.location.pathname);
    const url = `http://nationalngo.net${window.location.pathname}`;

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      "facebook-share-dialog",
      "width=626,height=436"
    );
  };
  return (
    <div>
      <div
        className="cause-area urgent-cause2 single-cause cause-list-bg "
        style={{ padding: "20px 0", backgroundColor: "#f8f9f6" }}
      >
        <article
          className="item clearfix"
          style={{
            background: "#fff",
            boxShadow: "5px 0px 8px #888888",
            width: "100%",
          }}
        >
          <h3 style={{ padding: "20px 20px 0px 20px" }}>Sponsor A Child</h3>
          <div style={{ padding: "20px" }}>
            <a
              className="fb btn"
              style={{
                backgroundColor: "#133783",
                color: "#fff",
                marginRight: 10,
              }}
              href="#shareonfacebook"
              onClick={() => openFacebookURL()}
            >
              <i className="fa fa-facebook fa-fw"></i>Share{" "}
              {window.innerWidth < 700 ? "" : "on Facebook"}
            </a>
            <a
              className="fb btn"
              style={{ backgroundColor: "#25D366", color: "#fff" }}
              href={`https://api.whatsapp.com/send?text=http://nationalngo.net${window.location.pathname}`}
              data-action="share/whatsapp/share"
            >
              <i className="fa fa-whatsapp fa-fw"></i>Share{" "}
              {window.innerWidth < 700 ? "" : "on Whatsapp"}
            </a>
          </div>
          <OwlCarousel className="owl-theme" loop margin={10} nav items={1}>
            <div className="item">
              <div className="img-box">
                <img
                  src={`/images/1658229639.jpeg`}
                  alt={""}
                  style={{ width: "98%" }}
                />
              </div>
            </div>
          </OwlCarousel>
        </article>
        <div id="myBtnContainer" style={{ padding: 10 }}>
          <button
            className={showTab === "story" ? "btn active" : "btn"}
            onClick={() => setshowTab("story")}
          >
            Story
          </button>

          <button
            className={showTab === "updates" ? "btn active" : "btn"}
            onClick={() => setshowTab("updates")}
          >
            Updates
          </button>
        </div>
        {window.innerWidth < 700 && <SponserChildSidebar />}
        {showTab === "story" && (
          <div
            className="text selector"
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              fontSize: "1.2em",
              fontWeight: 400,
              color: "#666666",
            }}
          >
            <p>
              {" "}
              In India, education is the route out of poverty for many children.
              It gives them a chance to gain the knowledge and skills needed to
              enhance their lives. But the truth is millions of children never
              see the inside of a classroom. However, the majority of people who
              live in villages have already understood the importance of
              education and know that it is the only way to get rid of poverty.
              But on the other hand, due to lack of resources like money they
              are unable to send their children to private schools and hence
              depend upon government schools for education. Although, the
              majority of children miss out on school because their families
              need them to earn money or keep them at home to help (especially
              girls) with chores. Therefore, by sacrificing their education,
              they get trapped in a cycle of poverty.
            </p>
            <p>
              When you sponsor a child with National Organisation For Social
              Empowerment, you not only provide a helping hand to the child in
              need but also reshape the lives of children in the community where
              the sponsored child lives, by confirming they get proper access to
              quality education, nutritious food, healthcare facilities and
              protection. Apart from that, your valuable sponsorship also helps
              poor and vulnerable communities to build a more empowering context
              for their children to be safeguarded and cared for.
            </p>
            <p>
              Our NGO is working at grassroots level and we endeavour towards
              making vulnerable communities aware of constitutional values and
              their rights including the rights of education. Your sponsorship
              empowers our efforts to educate the children in poor communities
              and save them from crimes like child labour, child trafficking,
              etc. In a nutshell, your sponsorship helps the children in
              becoming better citizens for a better tomorrow.
            </p>
            <h3> WHAT IS CHILD SPONSORSHIP? </h3>
            <p>
              {" "}
              Child sponsorship is a regular giving program run by National
              Organisation For Social Empowerment, in which your
              (monthly/quarterly/half yearly/annual) contribution ensures
              education, healthcare facilities and a safe childhood to children
              from the most marginalized communities in India. This is not only
              for poor children but also for the children with special ability
              and women & girls with limited or no access to schools or basic
              facilities.{" "}
            </p>
            <p>
              {" "}
              Our NGO keeps you informed and updated about the progress made in
              the lives of the sponsored and its community. To effectively
              support the sponsored child’s efforts as an ambassador of his/her
              community, we link each child to up to three donors.{" "}
            </p>
            <h3> BECOME A REGULAR DONOR </h3>
            <p>
              {" "}
              Regular giving (or direct debit) supports our NGO to outline
              long-term projects. There is an intense need for regular giving as
              the underprivileged people we work for, need urgent help every
              day. Your regular donation allows us to execute impactful
              programmes which will bring about a meaningful change in the lives
              of the underprivileged people.{" "}
            </p>
            <h3 style={{ position: "relative", paddingBottom: "10px" }}>
              {" "}
              WHEN YOU SPONSOR A CHILD{" "}
            </h3>
            <ul>
              <li>
                <i className="fa fa-arrow-right"></i> You are sponsoring a
                child’s education, healthcare facilities and safeguarding
                his/her childhood, and also improving the well-being of the
                community where she/he lives.{" "}
              </li>
              <li>
                <i className="fa fa-arrow-right"></i> You are not just helping
                the underprivileged child go to school but you’re also
                addressing all the factors that are keeping the child out of it.{" "}
              </li>
              <li>
                <i className="fa fa-arrow-right"></i> Your sponsorship is also
                helping in building opportunities for the child's family to have
                a feasible source of livelihood and income.{" "}
              </li>
            </ul>
            <h3>
              SPONSOR A CHILD TODAY AND START YOUR JOURNEY WITH NATIONAL NGO{" "}
            </h3>
            <p>
              Join us on our journey and see how your support reshapes the lives
              of underprovided children and their communities. You can donate
              either on a yearly basis or on a monthly basis.
            </p>
            <p>
              {" "}
              On a yearly basis, you can donate ₹ 9,000 per child per year. OR
              on a monthly basis, you can donate ₹750 per child per month
              through Credit Card Standing Instructions (CCSI) or Automated
              Clearing House (ACH), whichever mode of payment is preferred
              although we strongly recommend CCSI to ensure uninterrupted
              support for your sponsored child. We recommend a minimum of
              3-year-long support in the best interest of the child. National
              Organisation For Social Empowerment is a 12A registered
              organisation which means 50% of your donations are exempted from
              tax under Section 80G of the Income Tax Act, 1961{" "}
            </p>
            <h3> WAYS TO DONATE</h3>
            <p>
              {" "}
              There are several options available for donation. Apart from
              Credit Card and ACH, you can also pay through Cheque, Debit Cards,
              Net Banking or Mobile Payments. If you are looking at sponsoring
              through cheque or ACH, you can download our sponsorship form, fill
              it up and post it to us.
            </p>
            <h3> WHAT DO YOU GET IN RETURN?</h3>
            <p>
              {" "}
              <strong> WELCOME KIT </strong>{" "}
            </p>
            <p> Welcome Kit with details of the child you are linked to.</p>
            <p>
              {" "}
              <strong> ANNUAL REPORT</strong>{" "}
            </p>
            <p>
              {" "}
              An annual update from the community that the child belongs to.
            </p>
            <p>
              {" "}
              <strong> CHILD’S MESSAGE</strong>{" "}
            </p>
            <p> A bi-annual message from the child to you. </p>
            <h3>MONTHLY E-NEWSLETTER</h3>
            <p>
              {" "}
              An email update of various initiatives of the National
              Organisation For Social Empowerment.
            </p>
            <h3> PROJECT VISITS</h3>
            <p>
              {" "}
              An annual visit to the project of National Organisation For Social
              Empowerment under which the child is supported.{" "}
            </p>
            <p>
              {" "}
              <strong>
                {" "}
                **Only available to those who have chosen the sponsorship model{" "}
              </strong>{" "}
            </p>
          </div>
        )}
        {showTab === "updates" &&
          updates.map((update, index) => {
            return (
              <div style={{ background: "#fff", padding: "20px" }} key={index}>
                {renderHTML(update.update)}
              </div>
            );
          })}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  sponser: state.sponser,
});

export default connect(mapStateToProps, { getUpdates })(SingleSponser);
