import React, { useEffect, useState } from "react";

import axios from "axios";

function BagDonationSidebar({ item }) {
    const [activeTab, setactiveTab] = useState("yearly");
    const [submit, setSubmit] = useState(false);
    const [amount, setamount] = useState(0);
    const [donation, setDonation] = useState(0);
    const [currency, setcurrency] = useState("Rs");
    const [students, setstudents] = useState(1);
    const [duration, setDuration] = useState(6);
    console.log("Amount", amount);
    useEffect(() => {
        activeTab === "yearly"
            ? setDonation(parseInt(students) * 9000)
            : setDonation(parseInt(students) * 750);
    }, [activeTab, students]);
    const proceedDonate = async () => {
        setSubmit(true);
        console.log("Clicked", item);
        const order = await axios.get(
            `https://nationalngo.net/razorpay-api/pay.php?amount=${donation}&period=${activeTab}&interval=${duration}`
        );
        var options = {
            key: "rzp_live_YjsybpU5dNZ5B7", // Enter the Key ID generated from the Dashboard
            amount: donation * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "National NGO",
            description: "",
            image: "https://nationalngo.net/images/logo-dark.png",
            subscription_id: order.data, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            handler: async function (response) {
                console.log(response);
                // const postData = {
                //   name: name,
                //   phone: phone,
                //   email: email,
                //   amount: donation,
                //   note: response.razorpay_payment_id,
                // };
                // if (cause.__typename === "Projects") {
                //   postData.project = cause._id;
                // }
                // if (cause.__typename === "Cause") {
                //   postData.cause = cause._id;
                // }
                // await axios.post(`${URI}/donations`, postData);
                window.location.href = "/thank-you";
            },

            theme: {
                color: "#3399cc",
            },
        };

        var rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            alert("Payment Failed Please try again");
        });
        rzp1.open();
        setSubmit(false);
        // window.history.pushState(
        //   "item",
        //   "Item",
        //   `/donate-student?amount=${
        //     activeTab === "yearly"
        //       ? parseInt(students) * 9000
        //       : parseInt(students) * 750
        //   }&&currency=${currency}`
        // );
        // // history.push({ pathname: "/donate", state: { item, amount, currency } });
    };

    return (
        <div>
            <div>
                <div>
                    <div style={{ backgroundColor: "#fff", margin: "10px 0px" }}>
                        <div className="selector" style={{ padding: "20px 20px" }}>
                            <h3
                                style={{
                                    position: "relative",
                                    paddingBottom: "10px",
                                    marginBottom: "10px",
                                }}
                            >
                                Sponsor A Child
                            </h3>

                            <div
                                style={{
                                    display: "flex",
                                    margin: "10px",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <a
                                    className={activeTab === "yearly" ? "active btn" : "btn"}
                                    onClick={() => setactiveTab("yearly")}
                                >
                                    Yearly
                                </a>
                                <a
                                    className={activeTab === "monthly" ? "active btn" : "btn"}
                                    onClick={() => setactiveTab("monthly")}
                                >
                                    Monthly
                                </a>
                            </div>
                            <p> Your most generous donation </p>
                            <div className="form-group">
                                <label> Select No Of Students </label>
                                <select
                                    className="form-control"
                                    onChange={(e) => setstudents(e.target.value)}
                                >
                                    <option value="1">1 Student</option>
                                    <option value="2">2 Students</option>
                                    <option value="3">3 Students</option>
                                    <option value="4">4 Students</option>
                                    <option value="5">5 Students</option>
                                    <option value="6">6 Students</option>
                                    <option value="7">7 Students</option>
                                    <option value="8">8 Students</option>
                                    <option value="9">9 Students</option>
                                    <option value="10">10 Students</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label> Total Amount </label>
                                <input
                                    type="text"
                                    name="fname"
                                    className="form-control"
                                    value={
                                        activeTab === "yearly"
                                            ? parseInt(students) * 9000
                                            : parseInt(students) * 750
                                    }
                                    onChange={(event) => setamount(event.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label> Duration </label>
                                <select
                                    className="form-control"
                                    value={duration}
                                    onChange={(e) => setDuration(e.target.value)}
                                >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>

                            <div className="evnt">
                                <a href="#donate" onClick={() => proceedDonate()}>
                                    {submit ? "Processing..." : "donate Now"}
                                </a>
                            </div>
                        </div>
                        <div className="bar-cont">
                            <p>Payment options: Online, cheque pickups</p>
                            <span>or</span>
                            <img src="/images/scan-code.jpg" />
                            <p className="scaner">Scan &amp; donate with any app</p>
                        </div>
                        <div className="payment">
                            <ul>
                                <li>
                                    <img src="/images/bhim.png" alt="Bhim" />
                                </li>
                                <li>
                                    <img src="/images/g-pay.png" alt="Google Pay" />
                                </li>
                                <li>
                                    <img src="/images/paytm.png" alt="Paytm" />
                                </li>
                                <li>
                                    <img src="/images/pay.png" alt="Pay" />
                                </li>
                            </ul>
                            <div
                                className="payment"
                                style={{ textAlign: "center", padding: "20px" }}
                            >
                                <a href="#">Download payment receipt</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BagDonationSidebar