import api from "../utils/api-strapi";
import { ADD_DONATION, DONATION_ERROR } from "../constants/Types";

// Load User
export const addDonation = (formData, payment_uri) => async (dispatch) => {
  try {
    const res = await api.post("/donations", formData);
    console.log(res.data);
    const donor = res.data;
    let URI_PAYMENT = "https://pages.razorpay.com/homengo";
    if (payment_uri) {
      URI_PAYMENT = payment_uri;
    }

    const URI = encodeURI(
      `${URI_PAYMENT}?amount=${donor.Amount}&name=${donor.name}&email=${donor.email}&phone=${donor.phone}`
    );
    window.location.href = URI;
    dispatch({
      type: ADD_DONATION,
    });
  } catch (err) {
    dispatch({
      type: DONATION_ERROR,
    });
  }
};
