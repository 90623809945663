import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function SidebarAwards() {
  return (
    <div style={{ background: "#fff" }}>
      <div className="news-tag content">
        <h3 style={{ padding: "10px 10px" }}>AWARDS</h3>
      </div>
      <OwlCarousel
        items={1}
        autoplay={true}
        className="owl-theme"
        loop
        margin={10}
      >
        <div className="item">
          <img src="/assets/images/MS_Award.png" alt="national ngo" />
        </div>
        <div className="item">
          <img src="/assets/images/NCT_Award.png" alt="national ngo" />
        </div>
        <div className="item">
          <img src="/assets/images/NCT Award_1.png" alt="national ngo" />
        </div>
        <div className="item">
          <img src="/assets/images/NCT Award_2.png" alt="national ngo" />
        </div>
      </OwlCarousel>
    </div>
  );
}

export default SidebarAwards;
