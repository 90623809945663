import {
  GET_NATIONAL_CALAMITIES,
  NATIONAL_CALAMITIES_ERROR,
  NATIONAL_CALAMITIES_SINGLE,
  GET_SINGLE_CALAM,
} from "../constants/Types";

const initialState = {
  calams: [],
  calam: [],
  loading: true,
  error: {},
  calamity: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_NATIONAL_CALAMITIES:
      return {
        ...state,
        calams: payload,
        loading: false,
      };
    case NATIONAL_CALAMITIES_ERROR:
      return {
        ...state,
        error: "Something Went Wrong",
        loading: false,
      };
    case NATIONAL_CALAMITIES_SINGLE:
      return {
        ...state,
        calamity: payload,
        loading: false,
      };
    case GET_SINGLE_CALAM:
      return {
        ...state,
        calam: payload,
        loading: false,
      };

    default:
      return state;
  }
}
