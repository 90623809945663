import { ADD_DONATION, DONATION_ERROR } from "../constants/Types";

const initialState = {
  loading: true,
  donate_message: "",
  error: {},
};

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case ADD_DONATION:
      return {
        ...state,
        donate_message: "Donation Started",
        loading: false,
      };
    case DONATION_ERROR:
      return {
        ...state,
        error: "Something Went Wrong",
        loading: false,
      };

    default:
      return state;
  }
}
